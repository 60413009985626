import * as Datamap from 'datamaps'

function customsMap() {
  var config = $('#map_container').data('config');
  var fills = {
    defaultFill: "#F4F4F4",
    pink: '#0fa0fa'
  };
  config.forEach(c => fills[c.fillKey] = c.fillKey);

  var map = new Datamap({
    element: document.getElementById('map_container'),
    scope: 'world',
    setProjection: function(element) {
      var projection = d3.geo.equirectangular()
        .center([126, 33])
        .rotate([4.4, 0])
        .scale(1100)
        .translate([element.offsetWidth / 2, element.offsetHeight / 2]);
      var path = d3.geo.path()
        .projection(projection);

      return { path: path, projection: projection };
    },
    geographyConfig: {
      borderWidth: 1,
      borderOpacity: 1,
      borderColor: '#000',
      highlightFillColor: '#055F5B',
    },
    fills: fills
  });

  map.bubbles(config, {
    popupTemplate: function(geo, data) {
      return "<div class='hoverinfo'>" + data.name + "</div>";
    }
   });
}

export default customsMap;