// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import './stylesheets/application.scss'
import Chart from "chart.js/auto";
import * as bootstrap from 'bootstrap'
import customsMap from "./brands"
import "@fortawesome/fontawesome-free/css/all.css"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

import $ from 'jquery';
global.$ = jQuery;

require('select2')
window.Chart = Chart

global.toastr = require("toastr")

$(document).on("turbolinks:load", () => {
// $(document).ready(function() {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
  })

  $('.select2').select2();

  $('#brand_industries').select2({
    tags: true
  });

  $('.select2-remote').select2({
    minimumInputLength: 2,
    ajax: {
      dataType: 'json',
      processResults: function(data) {
        return { results: data };
      }
    }
  });
  
  $('.select2-multi').select2({
    tokenSeperators: [','],
    minimumInputLength: 2
  });

  $('.select2-multi-remote').select2({
    tokenSeperators: [','],
    minimumInputLength: 2,
    ajax: {
      dataType: 'json',
      processResults: function(data) {
        return { results: data };
      }
    }
  });

  $('.select2-multi-remote-brands').select2({
    tags: true,
    tokenSeperators: [','],
    minimumInputLength: 2,
    ajax: {
      dataType: 'json',
      processResults: function (data) {
        return { results: data };
      }
    }
  });

  if ($('#records_by_month').length) {
    var config = $('#records_by_month').data('config');
    var ctx = document.getElementById('records_by_month').getElementsByTagName('canvas')[0].getContext('2d');
    new Chart(ctx, config);
  }

  if ($('#records_by_customs').length) {
    var config = $('#records_by_customs').data('config');
    var ctx = document.getElementById('records_by_customs').getElementsByTagName('canvas')[0].getContext('2d');
    new Chart(ctx, config);
  }

  if ($('#enforcements_by_event').length) {
    var config = $('#enforcements_by_event').data('config');
    var ctx = document.getElementById('enforcements_by_event').getElementsByTagName('canvas')[0].getContext('2d');
    new Chart(ctx, config);
  }

  if ($('#enforcements_by_brand').length) {
    var config = $('#enforcements_by_brand').data('config');
    var ctx = document.getElementById('enforcements_by_brand').getElementsByTagName('canvas')[0].getContext('2d');
    new Chart(ctx, config);
  }

  if ($('#enforcements_by_authenticity').length) {
    var config = $('#enforcements_by_authenticity').data('config');
    var ctx = document.getElementById('enforcements_by_authenticity').getElementsByTagName('canvas')[0].getContext('2d');
    new Chart(ctx, config);
  }

  if ($('#enforcements_by_product_type').length) {
    var config = $('#enforcements_by_product_type').data('config');
    var ctx = document.getElementById('enforcements_by_product_type').getElementsByTagName('canvas')[0].getContext('2d');
    new Chart(ctx, config);
  }

  if ($('#map_container').length) {
    customsMap();
  }

  // Only load Brand logos if image found to avoid errors with pdf generation
  document.querySelectorAll('img.clearbit-logo').forEach(element => {
    try {
      var src = element.dataset.logoSrc;
      var xmlHttp = new XMLHttpRequest();
      xmlHttp.open( "GET", src, false);
      xmlHttp.send(null);
      if (xmlHttp.status === 200) {
        element['src'] = src;
      }
    } catch (error) {
    }
  });

  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl, {
      html: true
    })
  })
})

$(document).on('change', '#alert-toggle', function () {
  const url = $(this).data('url');
  const method = $(this).data('method');

  $.ajax({
    url: url,
    type: method,
    dataType: 'script'
  });
});